import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Map, GoogleApiWrapper, Marker } from "google-maps-react"

//

const SecondPage = ({ google }) => {
  const mapStyles = {
    width: "100%",
    height: "40vh",
    position: "relative",
  }

  const myHouseCoords = { lat: 53.22486, lng: 6.5471 }
  return (
    <Layout nomargin>
      <SEO title="Contact" />

      <div style={mapStyles}>
        <Map
          google={google}
          zoom={14}
          style={mapStyles}
          initialCenter={myHouseCoords}
          scrollwheel={false}
        >
          <Marker position={myHouseCoords} />
        </Map>
      </div>

      <div style={{ marginLeft: 40, marginTop: 40 }}>
        <h1>Contact</h1>
        <p>
          Email: <a href="mailto:info@leckr.io">info@leckr.io</a>
        </p>
      </div>
    </Layout>
  )
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCOENphOkWqcrvmehHhhgKu7lJpwqfLQzc",
})(SecondPage)
